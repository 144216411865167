// DataTableStatic.js

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useForceUpdate from 'use-force-update';
import {axiosInstance} from './axiosInstance';
import {hostUrl} from "./config";

const DataTableStatic = (props) => {
    const forceUpdate = useForceUpdate();
    const { resource, filterField, filterValue } = props;
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(50);

    useEffect(() => {
        fetchData(`${hostUrl}/${resource}/?format=json&limit=${pageSize}&offset=${(currentPage - 1) * pageSize}`);
    }, [hostUrl, resource, filterField, filterValue, currentPage]);

    const fetchData = (url) => {
        let apiUrl = url;
        if (filterField && filterValue) {
            const separator = url.includes('?') ? '&' : '?';
            apiUrl = `${url}${separator}${filterField}=${filterValue}`;
            console.log(apiUrl);
        }

        axiosInstance.get(apiUrl)
            .then(response => {
                const responseData = response.data.results || response.data;
                setData(responseData);

                if (responseData.length > 0) {
                    const keys = Object.keys(responseData[0]);
                    setHeaders(keys);
                } else {
                    setHeaders([]);
                }

                if (response.data.count) {
                    setTotalPages(Math.ceil(response.data.count / pageSize));
                }
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError('There was an error fetching the data.');
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    return (
        <div className="container mt-2">
            {data.length > 0 ? (
                <>
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index} scope="col">{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    {headers.map((header, headerIndex) => (
                                        <td key={headerIndex}>{item[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <div>No data available.</div>
            )}
        </div>
    );
};

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//         pageNumbers.push(i);
//     }
//
//     return (
//         <nav aria-label="Page navigation">
//             <ul className="pagination justify-content-center">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                     <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>&laquo; Previous</button>
//                 </li>
//                 {pageNumbers.map((number) => (
//                     <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                         <button className="page-link" onClick={() => onPageChange(number)}>{number}</button>
//                     </li>
//                 ))}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                     <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>Next &raquo;</button>
//                 </li>
//             </ul>
//         </nav>
//     );
// };
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    if (totalPages <= 1) return null; // Hide pagination if only 1 page

    const maxVisiblePages = 2; // Number of leading/trailing pages to show
    const pageNumbers = [];

    // Function to add a page button
    const addPageButton = (pageNumber) => {
        pageNumbers.push(
            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                <button className="page-link" onClick={() => onPageChange(pageNumber)}>{pageNumber}</button>
            </li>
        );
    };

    // Show first two pages
    for (let i = 1; i <= Math.min(maxVisiblePages, totalPages); i++) {
        addPageButton(i);
    }

    // Show ellipsis if there is a gap
    if (currentPage > maxVisiblePages + 1) {
        pageNumbers.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
    }

    // Show the current page and its neighbors
    for (
        let i = Math.max(maxVisiblePages + 1, currentPage - 1);
        i <= Math.min(totalPages - maxVisiblePages, currentPage + 1);
        i++
    ) {
        addPageButton(i);
    }

    // Show ellipsis if there is a gap before the last pages
    if (currentPage < totalPages - maxVisiblePages - 1) {
        pageNumbers.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
    }

    // Show last two pages
    for (let i = Math.max(totalPages - maxVisiblePages + 1, maxVisiblePages + 1); i <= totalPages; i++) {
        addPageButton(i);
    }

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>&laquo; Previous</button>
                </li>

                {pageNumbers}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>Next &raquo;</button>
                </li>
            </ul>
        </nav>
    );
};


export default DataTableStatic;
